
import { defineComponent, ref } from 'vue'
import WizardWhiteBlock from '@/components/pages/auth/wizard/WizardWhiteBlock.vue'
import TmButton from '@/components/shared/TmButton.vue'
import WizardFormLine from '@/components/pages/auth/wizard/WizardFormLine.vue'
import PageStep from '@/components/layout/PageStep.vue'
import { wizardSteps } from '@/definitions/auth/data'

export default defineComponent({
  components: {
    WizardWhiteBlock,
    WizardFormLine,
    TmButton,
    PageStep,
  },
  props: {
    stepSize: {
      type: String,
    },
  },
  setup() {
    const currentIndex = ref(2)
    const senderID = ref()

    return {
      wizardSteps,
      currentIndex,
      senderID,
    }
  },
})
